<template>
  <div class="z-[999] relative block lg:hidden">
    <div
      class="fixed inset-0 top-0 z-20 h-16 transition-all"
      :class="
        scrollPosition >= 80 || isBecomeParner
          ? 'bg-black bg-opacity-70 backdrop-blur-lg'
          : 'bg-transparent'
      "
    >
      <nav
        class="container flex items-center justify-between h-16"
        :class="scrollPosition >= 80 || isBecomeParner ? 'pt-0' : 'pt-4'"
      >
        <a
          href="#mmenu"
          tabindex="0"
          aria-label="Mobile sidebar mmenu toggle"
          class="z-10 text-xs font-semibold text-white md:text-base lg:text-xl hover:no-underline focus:no-underline hover:text-primary-500 focus:outline-none"
          @click.prevent="open"
        >
          <MenuIcon
            class="w-6 h-6"
            role="Mobile menu trigger item icon"
            :class="
              (isProductPage && scrollPosition >= 80) || isBecomeParner
                ? 'text-white'
                : !isProductPage
                ? 'text-white'
                : 'text-[#5a5a5a]'
            "
          />
        </a>

        <a
          class="absolute flex items-center justify-center transform -translate-s-1/2 rtl:left-[50%]"
          :class="[
            scrollPosition >= 80 || isBecomeParner ? 'top-[10px]' : '',
            store.locale !== 'ar' ? 'start-1/2' : '',
          ]"
          @click.prevent="onClickLink($i18nPath('/'))"
          href="#"
          :aria-label="`GocciaBike ${$t('common.home_title')}`"
        >
          <Logo class="transition-all h-11" />
        </a>

        <div class="flex items-center">
          <!-- Locale selector -->
          <LazyLocaleSelector name="headermobile" />
          <!-- <UserDropdown v-if="store.enableShop" name="headermobile" /> -->
        </div>
      </nav>
    </div>
    <nav v-show="visible" id="mmenu" ref="mmenu">
      <ul>
        <!-- Home -->
        <li
          :class="isActive('home') ? 'is-active' : ''"
          @click.prevent="onClickLink($i18nPath('/'))"
        >
          <a
            href="#"
            :aria-label="$t('common.home_title')"
            v-text="$t('common.home_title')"
          />
          <div></div>
        </li>

        <!-- E bikes -->
        <li v-if="products.type1 && products.type1.length >= 1">
          <span v-text="$t('common.e-bikes')" />
          <ul>
            <li v-for="(cat, cIndex) in products.type1" :key="cIndex">
              <span v-text="cat.name" />
              <ul>
                <template v-for="(products1, materialKey) in cat.products">
                  <li
                    v-for="(product, prdIndex) in products1"
                    :key="`${materialKey}-${prdIndex}`"
                    @click.prevent="
                      onClickLink($i18nPath(`/products/${product.slug}`))
                    "
                  >
                    <a
                      href="#"
                      :aria-label="product.name"
                      class="product__category"
                    >
                      <span v-text="product.name" />
                      <img
                        v-if="product.image"
                        :src="product.image"
                        loading="lazy"
                        class="mt-5 mb-2"
                      />
                    </a>
                    <div></div>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
          <div></div>
        </li>
        <!-- E motors -->
        <!-- <li v-if="products.type2 && products.type2.length >= 1">
          <span v-text="$t('common.e-motors')" />
          <ul>
            <li v-for="(cat, cIndex) in products.type2" :key="cIndex">
              <span v-text="cat.name" />
              <ul>
                <template v-for="(products2, materialKey) in cat.products">
                  <li
                    v-for="(product, prdIndex) in products2"
                    :key="`${materialKey}-${prdIndex}`"
                    @click.prevent="
                      onClickLink($i18nPath(`/products/${product.slug}`))
                    "
                  >
                    <a
                      href="#"
                      :aria-label="product.name"
                      class="product__category"
                    >
                      <span v-text="product.name" />
                      <img
                        v-if="product.image"
                        :src="product.image"
                        loading="lazy"
                        class="mt-5 mb-2"
                      />
                    </a>
                    <div></div>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
          <div></div>
        </li> -->

        <!-- Accesories -->
        <!-- <li v-if="categories.length > 0">
          <span v-text="$t('common.accesories')" />
          <ul>
            <li
              v-for="(cat, cIndex) in categories"
              :key="cIndex"
              @click.prevent="
                onClickLink(
                  $i18nPath(`/search/equipment?category=${cat.original_name}`)
                )
              "
            >
              <a v-text="cat.name" :aria-label="cat.name" href="#" />
              <div></div>
            </li>
          </ul>
          <div></div>
        </li> -->

        <!-- News -->
        <li
          v-if="currentSite && currentSite.news"
          :class="isActive('news') ? 'is-active' : ''"
          @click.prevent="onClickLink($i18nPath(store.pageLinks['news']))"
        >
          <a :aria-label="$t('common.news')" href="#">
            {{ $t('common.news') }}
          </a>
          <div></div>
        </li>

        <!-- About us -->
        <li
          :class="isActive('about-us') ? 'is-active' : ''"
          @click.prevent="onClickLink($i18nPath(store.pageLinks['about-us']))"
        >
          <a
            :aria-label="$t('common.about_us')"
            href="#"
            v-text="$t('common.about_us')"
          />
          <div></div>
        </li>

        <!-- Shop online -->
        <li
          v-if="store.enableShop"
          :class="isActive('search') ? 'is-active' : ''"
          @click.prevent="onClickLink($i18nPath(store.pageLinks['search']))"
        >
          <a :aria-label="$t('common.shop_online')" href="#">
            {{ $t('common.shop_online') }}
          </a>
        </li>

        <!-- Stores -->
        <!-- v-if="store.enableShop" -->
        <!-- <li
          :class="isActive('stores') ? 'is-active' : ''"
          @click.prevent="onClickLink($i18nPath(`/stores`))"
        >
          <a
            :aria-label="$t('common.stores')"
            href="#"
            v-text="$t('common.stores')"
          />
          <div></div>
        </li> -->

        <!-- Contact us -->
        <li
          :class="isActive('contact') ? 'is-active' : ''"
          @click.prevent="onClickLink($i18nPath(store.pageLinks['contact']))"
        >
          <a
            :aria-label="$t('common.contact_us')"
            href="#"
            v-text="$t('common.contact_us')"
          />
          <div></div>
        </li>
      </ul>
      <div
        class="w-full h-[60px] bg-white flex justify-center items-center absolute bottom-0 z-50"
      >
        <button @click="close">
          <CloseIcon class="h-9 w-h-9 text-primary-500" />
        </button>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { useMainStore } from '@/store/index'
import { useTranslation } from 'i18next-vue'
import MmenuLight from 'mmenu-light/esm/core/index'
import CloseIcon from '@/assets/svg/heroicons/x-circle.svg?component'
import MenuIcon from '@/assets/svg/heroicons/menu.svg?component'

const store = useMainStore()
const route = useRoute()
const router = useRouter()
const { t } = useTranslation()

const visible = ref(false)
const isProductPage = ref(false)
const isBecomeParner = ref(false)
const isMenuShow = ref(false)
const scrollPosition = ref(0)
const mmenu = ref(null)
const drawer = ref(null)

const products = store.products
const categories = store.categories
const currentSite = store.currentSite
const currentSiteSettings = store.currentSiteSettings

watch(
  router.currentRoute,
  (newValue, oldValue) => {
    if (newValue.name == 'country-products-slug') {
      isProductPage.value = true
    } else {
      isProductPage.value = false
    }
    if (newValue.name == 'country-become-partner') {
      isBecomeParner.value = true
    } else {
      isBecomeParner.value = false
    }
  },
  { deep: true }
)

const loadModule = () => {
  if (mmenu.value) {
    const menu = new MmenuLight(mmenu.value, '(max-width: 1024px)')
    /* eslint-disable-next-line */
    const navigator = menu.navigation({
      title: t('common.menu'),
    })
    drawer.value = menu.offcanvas({
      position: store.isRtl ? 'right' : 'left',
    })
  }
}

onMounted(() => {
  if (!import.meta.browser) return false
  isProductPage.value = window.location.href.includes('/products/')
  isBecomeParner.value = window.location.href.includes('/become-partner')

  loadModule()

  window.addEventListener('scroll', updateScroll)

  var elements = document.querySelectorAll('.mm-ocd__backdrop')

  elements[0].addEventListener('touchend', onToggleMenu)
  elements[0].addEventListener('mouseup', onToggleMenu)

  visible.value = true
})

onUnmounted(() => {
  window.removeEventListener('scroll', updateScroll)
  removeListener()
})

const removeListener = () => {
  var elements = document.querySelectorAll('.mm-ocd__backdrop')

  elements[0].removeEventListener('touchend', onToggleMenu)
  elements[0].removeEventListener('mouseup', onToggleMenu)
}
const isActive = (active) => {
  if (active == 'home' && route.name == 'country') {
    return true
  }
  return route.path.includes(active)
}
const onToggleMenu = () => {
  isMenuShow.value = false
}
const onClickLink = (route) => {
  if (drawer.value) {
    drawer.value.close()
  }
  isMenuShow.value = false
  router.push(route)
}
const open = () => {
  if (drawer.value) {
    drawer.value.open()
  }
  isMenuShow.value = true
}
const close = () => {
  if (drawer.value) {
    drawer.value.close()
  }
  isMenuShow.value = false
}
const updateScroll = () => {
  scrollPosition.value = window.scrollY
}
</script>

<style lang="postcss">
@import 'mmenu-light/dist/mmenu-light.css';

.mm-spn.mm-spn--light {
  background-color: #fff;
}

.mm-spn a:not(:last-child)::after {
  border-color: transparent;
}

.mm-spn.mm-spn--navbar::after {
  background-color: #f3f4f6;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.6;
}

.mm-spn.mm-spn--navbar ul::before {
  border-color: transparent;
}

.mm-spn--open {
  li a,
  li span {
    font-size: 18px;
  }
}

.main-nav {
  padding: 1rem;

  & .main-nav__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;

    & a[href='#mmenu'] {
      width: 30px;

      &:hover {
        background-size: 0;
      }
    }

    & .main-nav__logo {
      &:hover {
        background-size: 0;
      }

      img {
        height: 54px;
      }
    }

    & .dropdown button .bag {
      padding: 0 12px;
    }
  }
}

.product__category {
  display: flex;
  flex-direction: row;

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
